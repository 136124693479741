const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    siteData: [],
    statusCounts:[],
}

const siteVisitReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'ALL_SITE_TRACKING_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data.docs,
                total: action.data.total,
                params: action.params,
            }
        

        default:
            return { ...state }
    }
}
export default siteVisitReducer
