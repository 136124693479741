const initialState = {
  data: [],
  total: 1,
  params: {},
  allProjectData: [],
  singleProjectData: {},
  cityListData: [],
  branchListData: [],
  areaListData:[]

}

const pmsReportReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'ALL_PROJECT_PROGRESS_DATA':
      return {
        ...state,
        allProjectData: action.data
      }
    case 'SINGLE_PROJECT_DATA':
      return {
        ...state,
        singleProjectData: action?.data
      }
      case "GET_BRANCH_LIST_JOB":
        return {
          ...state,
          branchListData: action?.branchListData,
        };
        case "GET_AREA_LIST":
        return {
          ...state,
          areaListData: action?.areaListData,
        };
        case "GET_CITY_LIST":
          return {
            ...state,
            cityListData: action.cityListData,
          };
    default:
      return { ...state }
  }
}
export default pmsReportReducer
